import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';

ReactDOM.render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>
    document.getElementById('root'),
);
